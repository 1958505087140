html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.owl-stage {
  margin: 20px 1px;
}

.button-link {
  padding: 0;
  width: 100%;
  margin-bottom: 1em;
  text-align: left;
}

canvas {
  width: 100%;
  height: 100%
}

.ant-input {
  background: #ffffff;
  border: 0 solid #E5E5E5;
  border-bottom-width: 1px;
  box-sizing: border-box;
  border-radius: 0px;
  height: 55px;
  font-size: 34px;
  line-height: 53px;
  padding-left: 0px;
}

.ant-input:focus, .ant-input:hover, .ant-input-focused {
  box-shadow: none !important;
  border-right-width: 0 !important;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  width: 100% !important;
}

.ant-form-vertical .ant-form-item {
  flex-direction: row;
}

@media (min-width: 663px) and (max-width: 1024px) {
  .ant-form-vertical .ant-form-item {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 70% !important;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 50% !important;
  }
}
@media (min-width: 1281px) {
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 40% !important;
  }

  .ant-form-vertical .ant-form-item {
    flex-direction: column;
  }
}


.slick-list,
.slick-track {
  height: calc(100vh - 12px) !important;
}

.slick-vertical .slick-slide {
  height: inherit;
}
.slick-vertical .slick-slide > div:nth-of-type(1) {
  height: 100%;
}

